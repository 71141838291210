export default [
  // {
  //   id: 1,
  //   title: 'Update account',
  //   linkTo: '/settings',
  //   bgColor: '#FFEF66',
  //   emoji: '👋',
  //   isPrivate: true
  // },
  // {
  //   id: 2,
  //   title: 'Shop Items',
  //   linkTo: '/settings',
  //   bgColor: '#83E8DF',
  //   emoji: '👚',
  //   isPrivate: true
  // },
  {
    id: 3,
    title: 'Account Info & Password',
    linkTo: '/settings/account-info',
    bgColor: '#4455BB',
    emoji: '🔑',
    isPrivate: true
  },
  // {
  //   id: 4,
  //   title: 'Accepted Tokens',
  //   linkTo: '/settings/accepted-tokens',
  //   bgColor: '#99CC66',
  //   emoji: '💱',
  //   isPrivate: false
  // },
  {
    id: 5,
    title: 'Wallet Address',
    linkTo: '/settings/wallet-address',
    bgColor: '#000000',
    emoji: '💰',
    isPrivate: false
  },
  {
    id: 6,
    title: 'Required Confirmations',
    linkTo: '/settings/required-confirmations',
    bgColor: '#FF9A75',
    emoji: '🔗',
    isPrivate: false
  },
  {
    id: 7,
    title: 'Base Currency',
    linkTo: '/settings/base-currency',
    bgColor: '#D57FE6',
    emoji: '💵',
    isPrivate: false
  }
];
