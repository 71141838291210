import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isDesktop } from '../utils/helpers';
import barsImg from '../assets/images/bars.png';
import backImg from '../assets/images/back.png';
import logoImg from '../assets/images/logo.png';

const Section = styled.section`
  grid-area: header;
  padding: 1rem 1.5rem;
  border-top: ${props => `1px solid ${props.theme.borderColor}`};
  border-left: ${props => `1px solid ${props.theme.borderColor}`};
  border-right: ${props => `1px solid ${props.theme.borderColor}`};
  @media only screen and (max-width: ${props => props.theme.mobileBreakpoint}) {
    padding: 0rem 1.5rem;
  }
`;
const Container = styled.div`
  height: 120px;
  @media only screen and (max-width: ${props => props.theme.mobileBreakpoint}) {
    height: 90px;
  }
`;

const Columns = styled.div`
  height: 100%;
`;
const LeftSide = styled.div`
  &&&&& {
    display: flex;
    align-items: center;
    width: 10%;
  }
`;
const LeftLink = styled.a`
  cursor: pointer;
`;

const LogoContainer = styled.div`
  padding-top: 4px;
  margin-right: 4rem;
  align-items: center;
  @media only screen and (max-width: ${props => props.theme.mobileBreakpoint}) {
    justify-content: center;
    margin-right: 2rem;
  }
`;
const Logo = styled.img`
  width: 58px;
  height: auto;
`;

const MenuItem = styled.a`
  font-size: 18px;
  padding: 0.5rem 2.75rem;
  &.is-active {
    font-weight: 500;
  }
`;

const Nav = styled.nav`
  z-index: 0;
  height: 93%;
  align-items: center;
  @media only screen and (max-width: ${props => props.theme.mobileBreakpoint}) {
    margin-top: 10px;
  }
`;

const Header = props => {
  const {
    leftIcon,
    hideNav,
    isVisible,
    title,
    leftBtnClick,
    onNavItemClick,
    activeNavItem,
    isReady
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <Section className={`whiteBG ${isDesktop() ? 'container' : ''}`}>
      <Container className="">
        <Columns className="columns is-mobile">
          <LeftSide className="column is-one-fifth">
            <LeftLink onClick={leftBtnClick}>
              {leftIcon === 'menu' && <img src={barsImg} alt="side menu" />}
              {leftIcon === 'back' && <img src={backImg} alt="back button" />}
            </LeftLink>
          </LeftSide>
          {hideNav ? (
            <React.Fragment>
              <div className="column is-four-fifths has-text-centered">
                <span className="is-family-secondary">{title}</span>
              </div>
              <div className="column is-one-fifth" />
            </React.Fragment>
          ) : (
            <div className="column">
              <Nav
                className="navbar"
                role="navigation"
                aria-label="main navigation"
              >
                <LogoContainer className="navbar-brand">
                  <Logo src={logoImg} alt="Dexpay logo" />
                </LogoContainer>

                <div className="navbar-menu">
                  <div className="navbar-start">
                    {isReady ? (
                      <React.Fragment>
                        <MenuItem
                          className={`navbar-item ${activeNavItem ===
                            'numberPad' && 'is-active'}`}
                          onClick={() => onNavItemClick('numberPad')}
                        >
                          Number Pad
                        </MenuItem>
                        <MenuItem
                          className={`navbar-item ${activeNavItem ===
                            'productItems' && 'is-active'}`}
                          onClick={() => onNavItemClick('productItems')}
                        >
                          Product Items
                        </MenuItem>
                        <MenuItem
                          className={`navbar-item ${activeNavItem ===
                            'recentPayments' && 'is-active'}`}
                          onClick={() => onNavItemClick('recentPayments')}
                        >
                          Recent Transactions
                        </MenuItem>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </Nav>
            </div>
          )}
        </Columns>
      </Container>
    </Section>
  );
};

Header.defaultProps = {
  leftIcon: 'menu',
  hideNav: false,
  isVisible: true,
  title: undefined,
  leftBtnClick: () => {},
  onNavItemClick: () => {},
  isLoggedIn: false
};

Header.propTypes = {
  leftIcon: PropTypes.string,
  hideNav: PropTypes.bool,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  leftBtnClick: PropTypes.func,
  onNavItemClick: PropTypes.func,
  isLoggedIn: PropTypes.bool
};

export default Header;
