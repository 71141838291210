export default {
  isLoggedIn: false,
  user: {
    id: null,
    fullName: null,
    shopName: null,
    email: null,
    __typename: 'User'
  }
};
